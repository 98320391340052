import React, { useState, useEffect } from 'react';
import { doc, getDoc, getFirestore, getDocs, collection, updateDoc } from "firebase/firestore";
import { app, auth, firestore, httpsCallable, functions } from '../firebase.js';
import CarInterventionModal from './carInterventionModal.jsx';
import AddCar from './addCar.jsx';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

const ClientEditModal = ({ show, onClose, clientId }) => {
  const [client, setClient] = useState(null);
  const [showInterventions, setShowInterventions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [currentCarId, setCurrentCarId] = useState(null);
  const db = getFirestore(app);
  const [refreshKey, setRefreshKey] = useState(false);
  const [isAddCarModalOpen, setIsAddCarModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cars, setCars] = useState([]);

  useEffect(() => {
    setFirstName(client?.firstName || '');
    setLastName(client?.lastName || '');
    setEmail(client?.email || '');
    setPhone(client?.phone || '');
    setCars(client?.cars || []);
  }, [client]);

  const handleSave = async (clientId) => {
    const user = auth.currentUser;
    const docRef = doc(db, 'customers', user.uid, 'clients', clientId);
    await updateDoc(docRef, {
      firstName,
      lastName,
      phone,
      email,
    });
    alert("Clientul a fost actualizat cu succes!");
  };

  const handleSaveITP = async (clientId, carId, newEndDate) => {
    const user = auth.currentUser;
    const carRef = doc(db, 'customers', user.uid, 'clients', clientId, 'car', carId);
    try {
      await updateDoc(carRef, {
        'itp.endDate': newEndDate
      });
      alert("Data de expirare ITP a fost actualizată cu succes!");
      setRefreshKey(prev => !prev);
    } catch (error) {
      console.error("Error updating ITP end date:", error);
      alert("A apărut o eroare la actualizarea datei de expirare ITP.");
    }
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (clientId && user) {
      const clientRef = doc(db, `customers/${user.uid}/clients/${clientId}`);
      getDoc(clientRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            let clientData = docSnapshot.data();
            const carCollectionRef = collection(db, `customers/${user.uid}/clients/${clientId}/car`);
            getDocs(carCollectionRef)
              .then(async (carSnapshot) => {
                let cars = [];
                if (!carSnapshot.empty) {
                  for (const doc of carSnapshot.docs) {
                    let carData = doc.data();
                    carData.id = doc.id;
                    carData.itp = carData.itp || { endDate: '' };
                    const interventionCollectionRef = collection(db, `customers/${user.uid}/clients/${clientId}/car/${carData.id}/interventions`);
                    const interventionSnapshot = await getDocs(interventionCollectionRef);
                    if (!interventionSnapshot.empty) {
                      let interventions = interventionSnapshot.docs.map((doc) => doc.data());
                      carData.interventions = interventions;
                    }
                    cars.push(carData);
                  }
                } else {
                  console.log("No cars found!");
                }
                clientData.cars = cars;
                setClient(clientData);
                setCars(cars);
              })
              .catch((error) => {
                console.error("Error reading cars from Firestore:", error);
              });
          } else {
            console.log("No such client document!");
          }
        })
        .catch((error) => {
          console.error("Error reading client from Firestore:", error);
        });
    }
  }, [clientId, db, refreshKey]);

  const exportInterventions = async (clientId, carId) => {
    const userId = auth.currentUser.uid;

    const interventionsRef = collection(
      firestore,
      'customers',
      userId,
      'clients',
      clientId,
      'car',
      carId,
      'interventions'
    );

    const carRef = doc(firestore, 'customers', userId, 'clients', clientId, 'car', carId);
    const carSnapshot = await getDoc(carRef);
    const car = carSnapshot.data();
    const interventionsSnapshot = await getDocs(interventionsRef);

    const interventions = interventionsSnapshot.docs.map(doc => doc.data());

    interventions.sort((a, b) => new Date(a.date) - new Date(b.date));
    const pdf = new jsPDF();

    const columns = ["Date", "Denumire","Um", "Cantitate", "Pret Unitar", "Valoare", "Valoare Totala"];
    const data = interventions.map(intervention => [
      intervention.date,
      intervention.denumire,
      intervention.um,
      intervention.cantitate,
      intervention.pretUnitar,
      intervention.valoare,
      intervention.valtotal
    ]);

    const totalPrice = interventions.reduce((total, intervention) => total + Number(intervention.valtotal), 0);

    pdf.autoTable({
      startY: 25,
      head: [["Data", "Denumire", "Um", "Cantitate", "Pret Unitar", "Valoare", "Valoare Totala"]],
      body: data,
      foot: [['Total', interventions.length, `${totalPrice.toFixed(2)} RON`]],
      styles: { fontSize: 10 },
      footStyles: { fontSize: 10, fontStyle: 'bold' },
      didDrawPage: (data) => {
        const doc = data.doc;
        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text(`Client: ${client.firstName} ${client.lastName}`, data.settings.margin.left, 10);
        doc.text(`Model masina: ${car.description}`, data.settings.margin.left, 15);
        doc.text(`Nr. inmatriculare: ${car.position}`, data.settings.margin.left, 20);
      },
    });

    const fileName = `interventii-${client.firstName}-${client.lastName}-${car.position}.pdf`;
    pdf.save(fileName);
  };

  const deleteCar = async (clientId, carId) => {
    if (!clientId || !carId) {
      return;
    }

    const data = {
      clientID: clientId,
      carID: carId,
    };
    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations({ operation: 'delete', data: data });
      console.log(result.data);

      console.log(`Car with id ${carId} has been deleted.`);
      setRefreshKey(prevKey => !prevKey);
    } catch (error) {
        console.error('Error deleting car: ', error);
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="z-10 right-0 absolute h-full w-full top-0 bg-gray-50 dark:bg-gray-900 p-4" id="editClientModal">
      <div className="relative flex flex-col w-full top-0 gap-2 border border-gray-200 dark:bg-gray-800 dark:border-gray-700 rounded-lg">
        <div className="flex items-start justify-between p-4 border-b rounded-t-lg bg-white dark:bg-gray-800 dark:border-gray-700">
          <h3 className="text-xl font-bold dark:text-white">Vizualizare client</h3>
          <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 p-4">
          <h3 className="text-xl font-semibold dark:text-white mb-4">Detalii client</h3>
          <div className="flex justify-between gap-5">
            <div className="w-1/2">
              <div className="grid grid-cols-6 gap-6 w-full">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="clientType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tip</label>
                  <p id="clientType1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                    {client?.clientType}
                  </p>
                </div>
                {client?.clientType === "Persoana Juridica" && (
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="denumireOficiala" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Denumire Oficiala</label>
                    <p id="denumireOficiala" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                      {client?.denumireOficiala}
                    </p>
                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {client?.clientType === "Persoana Juridica" ? "Prenume Reprezentant" : "Prenume"}
                  </label>
                  <input
                    id="firstName1"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {client?.clientType === "Persoana Juridica" ? "Nume Reprezentant" : "Nume"}
                  </label>
                  <input
                    id="lastName1"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telefon</label>
                  <input
                    id="phone1"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  />
                </div>
                <div className="col-span-6 sm:col-span-3 mb-4">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="shadow-sm xs:w-fit md:w-fit min-w-full bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                  />
                </div>
              </div>
            </div>
            <div className="w-1/2 gap-6 flex flex-col">
              <div className="col-span-6 sm:col-span-2">
                <label htmlFor="nrMasini" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Mașini</label>
                <p id="nrMasini" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                  {client?.metrics.nrMasini}
                </p>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="programari" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Programări</label>
                <p id="programari" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                  {client?.metrics.programari}
                </p>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="confirmate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirmate</label>
                <p id="confirmate" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                  {client?.metrics.confirmate}
                </p>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="refuzate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Refuzate</label>
                <p id="refuzate" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                  {client?.metrics.refuzate}
                </p>
              </div>
            </div>
          </div>
          <div className="gap-5 flex">
            <button
              type="button"
              onClick={() => handleSave(clientId)}
              data-modal-toggle="add-user-modal"
              className="mt-5 inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 sm:w-auto dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
            >
              Salvează
            </button>
            <button
              type="button"
              data-modal-toggle="add-user-modal"
              className="mt-5 inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 sm:w-auto dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
              onClick={() => {
                setCurrentClientId(clientId);
                setIsAddCarModalOpen(true);
              }}
            >
              <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fillRule="evenodd" stroke="currentColor" strokeWidth="2" d="M12 22c-.552 0-1-.448-1-1v-8H3c-.552 0-1-.448-1-1s.448-1 1-1h8V3c0-.552.448-1 1-1s1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1z" clipRule="evenodd" />
              </svg>
              Mașină
            </button>
          </div>
          <hr className="my-5"></hr>
          <h3 className="text-xl font-semibold dark:text-white mb-4">Mașini client</h3>
          <div className="w-full">
            {cars.map((car, index) => (
              <div key={index} className="flex flex-col md:flex-col md:gap-5 rounded-lg shadow-sm mb-4 xs:flex-col">
                <div className="flex flex-col md:flex-row xs:flex-row">
                  <div className="flex flex-row md:flex-col md:w-1/2 gap-5 border-t pt-2 xs:flex-col">
                    <div className="w-1/3 mb-2 md:mb-0 xs:w-fit md:w-1/2">
                      <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Marca</label>
                      <p id={`car-description-first-${index}`} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                        {car.description.split(" ").slice(0, Math.ceil(car.description.split(" ").length / 2)).join(" ")}
                      </p>
                    </div>
                    <div className="w-1/3 xs:w-fit md:w-1/2">
                      <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Model</label>
                      <p id={`car-description-second-${index}`} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                        {car.description.split(" ").slice(Math.ceil(car.description.split(" ").length / 2)).join(" ")}
                      </p>
                    </div>
                    <div className="w-1/3 xs:w-fit md:w-1/2">
                      <label htmlFor="" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nr. inmatriculare</label>
                      <p id={`car-position-${index}`} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
                        {car.position}
                      </p>
                    </div>
                    <div className="w-1/3 xs:w-fit md:w-1/2">
                      <label htmlFor={`car-itp-endDate-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data expirare ITP</label>
                      <input
                        type="date"
                        id={`car-itp-endDate-${index}`}
                        value={car.itp?.endDate || ''}
                        onChange={(e) => {
                          const newCars = [...cars];
                          if (!newCars[index].itp) newCars[index].itp = {};
                          newCars[index].itp.endDate = e.target.value;
                          setCars(newCars);
                        }}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row mt-4 justify-between md:flex-col md:w-1/2 md:justify-end md:border-t xs:border-t">
                    <button
                      type="button"
                      data-modal-toggle="add-user-modal"
                      className="inline-flex xs:mb-2 md:mb-2 xs:mt-5 md:mt-5 items-center justify-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 mr-2 mb-2"
                      onClick={() => setShowInterventions(!showInterventions)}
                    >
                      Intervenții
                    </button>
                    <div className="flex flex-row md:flex-col xs:gap-2 md:gap-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 mr-2 mb-2 md:mb-0"
                        onClick={() => handleSaveITP(clientId, car.id, car.itp?.endDate)}
                      >
                        Salvează
                      </button>
                      <button
                        type="button"
                        data-modal-toggle="add-user-modal"
                        data-client-id={car.id}
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 mr-2 mb-2 md:mb-0"
                        onClick={() => {
                          setCurrentClientId(clientId);
                          setCurrentCarId(car.id);
                          setIsModalOpen(true);
                        }}
                      >
                        <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path fillRule="evenodd" stroke="currentColor" strokeWidth="2" d="M12 22c-.552 0-1-.448-1-1v-8H3c-.552 0-1-.448-1-1s.448-1 1-1h8V3c0-.552.448-1 1-1s1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1z" clipRule="evenodd" />
                        </svg>
                        Intervenție
                      </button>
                      <button
                        type="button"
                        data-modal-toggle="add-user-modal"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 mr-2 mb-2 md:mb-0"
                        onClick={() => exportInterventions(clientId, car.id)}
                        carId={car.id}
                        clientId={currentClientId}
                      >
                        <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                        </svg>
                        Istoric
                      </button>
                      <button
                        type="button"
                        data-modal-toggle="add-user-modal"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 mr-2 mb-2 md:mb-0"
                        clientId={currentClientId}
                        carId={car.id}
                        onClick={() => deleteCar(clientId, car.id)}
                      >
                        <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                        Șterge
                      </button>
                    </div>
                  </div>
                </div>
                {showInterventions && (
                  <div className="mt-4">
                    <h4 className="text-lg font-semibold dark:text-white mb-2">Intervenții</h4>
                    {car.interventions && car.interventions.length > 0 ? (
                      <ul className="list-disc pl-5">
                        {car.interventions.map((intervention, idx) => (
                          <li key={idx} className="mb-2">
                            <span className="font-medium">Data:</span> {intervention.date}, <span className="font-medium">Denumire:</span> {intervention.denumire}, <span className="font-medium">Valoare totală:</span> {intervention.valtotal} RON
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Nu există intervenții pentru această mașină.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CarInterventionModal
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          clientId={currentClientId}
          carId={currentCarId}
        />
      )}
      {isAddCarModalOpen && (
        <AddCar
          show={isAddCarModalOpen}
          onClose={() => setIsAddCarModalOpen(false)}
          clientId={currentClientId}
        />
      )}
    </div>
  );
};

export default ClientEditModal;